import { navigate } from 'gatsby'
import React from 'react'

import { Container } from 'components/Container'

const IndexPage: React.FC = () => {
  /**
   * Use effect hook in order to simulate the componentDidMount lifecycle. This
   * because `navigate(to)` function can only be called when window object is
   * defined.
   */
  React.useEffect(() => {
    navigate('brand__introduction')
  }, [])
  /**
   * Return redirecting message
   */
  return <Container>Redirecting...</Container>
}

export default IndexPage
